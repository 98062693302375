<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title">
      <h3 class="breadcrumb-header">Rechargement</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td colspan="3">
                      <input type="file" (change)="fileChange($event)" id="fichier" class="form-control" />
                      <div style="border: 0; font-size: 14px; color: green;" *ngIf="!upload && nbImporter > 0">
                        {{nbImporter}} codes importés avec succès
                      </div>
                      <div style="border: 0; font-size: 14px;" *ngIf="upload">
                        <img src="assets/img/administration/downloading.gif" style="width: 60px;" />
                        Fichier en cours de téléchargement
                      </div>
                    </td>
                    <td colspan="2">
                      <button class="btn btn-success" (click)="import()">Importer</button>
                    </td>
                    <td colspan="2">
                      <select class="form-control" [(ngModel)]="amount">
                        <option>Recharge...</option>
                        <option *ngFor="let d of detail" [value]="d.id">{{d.operateur}} - {{d.montant}} € ({{d.total}})</option>
                      </select>
                    </td>
                    <td colspan="2">
                      <button class="btn btn-success" (click)="export()">Exporter</button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <button (click)="recharger()" class="form-control btn-success">Sauvegarder</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="checkbox" (click)="multipleChoose('all', 1)" [(ngModel)]="all" />
                    </td>
                    <td *ngFor="let i of init">
                      <input type="checkbox" (click)="multipleChoose('colonne', i-1)" [(ngModel)]="colonne[i-1]" />
                    </td>
                  </tr>
                  <tr *ngFor="let ss of slots">
                    <td>
                      <input type="checkbox" (click)="multipleChoose('ligne', ss.numLigne-1)" [(ngModel)]="ligne[ss.numLigne-1]" />
                    </td>
                    <td *ngFor="let s of ss">
                      <input type="checkbox" (click)="clic_checkbox(s.port)" [checked]="s.asRecharger != ''" />
                      <div *ngIf="s.actif">
                        <strong>{{s.port}}</strong>
                      </div>
                      <div *ngIf="!s.actif">
                        {{s.port}}
                      </div>
                      <div *ngIf="s.codeEnAttente != ''">
                        {{s.operateur}} - {{s.montant}} €
                        <span (click)="removeRecharge(s.port)" style="cursor:pointer; display: inline-block; background-color: red; border-radius: 50%; width: 16px; color: white; text-align: center; height: 22px;">x</span>
                      </div>
                      <div>
                        <span *ngIf="s.dernierRechargement == ''">Aucun rechargement</span>
                        <span *ngIf="s.dernierRechargement != ''">
                        {{s.dernierRechargement * 1000 | date: 'dd/MM/yyyy'}} - {{s.dernierRechargementOperateur}} : {{s.dernierRechargementMontant}} €
                      </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <button (click)="recharger()" class="form-control btn-success">Sauvegarder</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
