<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title">
      <h3 class="breadcrumb-header">Utilisateurs</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td>
                      Utilisateurs
                    </td>
                    <td>
                      Password
                    </td>
                    <td>
                      Type utilisateur
                    </td>
                    <td>
                      Crédit disponible
                    </td>
                    <td>
                      Ajouter du crédit
                    </td>
                    <td>
                      Activé mise en stop
                    </td>
                    <td>
                      Ajouter
                    </td>
                    <td>
                      Action
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" class="form-control" [(ngModel)]="user.identifiant" placeholder="Login..." />
                    </td>
                    <td>
                      <input type="password" class="form-control" [(ngModel)]="user.password" placeholder="Password..." />
                    </td>
                    <td colspan="4">
                      <select class="form-control" [(ngModel)]="user.typeAccount">
                        <option value="">Type utilisateur...</option>
                        <option value="client">Client</option>
                        <option value="smpp">SMPP</option>
                        <option value="admin">Administrateur</option>
                      </select>
                    </td>
                    <td colspan="2">
                      <button class="btn btn-success" (click)="addUser()">Ajouter</button>
                    </td>
                  </tr>
                  <tr *ngFor="let u of users">
                    <td colspan="2">
                      {{u.identifiant}}
                    </td>
                    <td>
                      {{u.typeAccount}}
                    </td>
                    <td>
                      {{u.credit}}
                    </td>
                    <td>
                      <input class="form-control" [(ngModel)]="u.nouveauCredit" placeholder="Nouveau crédit..." />
                    </td>
                    <td>
                      <select (change)="updateActivatedStop(u._id)" [(ngModel)]="u.activatedStop" class="form-control">
                        <option [value]="true" [selected]="u.activatedStop">Activé</option>
                        <option [value]="false" [selected]="!u.activatedStop">Désactivé</option>
                      </select>
                    </td>
                    <td>
                      <button class="btn btn-success" (click)="updateCredit(u._id)">Ajouter</button>
                    </td>
                    <td>
                      <button class="btn btn-danger" (click)="deleteUsers(u._id)">Supprimer</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
