import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-ports',
  templateUrl: './ports.component.html',
  styleUrls: ['./ports.component.css']
})
export class PortsComponent implements OnInit {
  ports: any = [];
  indicationSfrs: any = [];
  users: any = [];
  general = "";
  type = "";
  status = "";
  testAutomatique = "n";
  use = "n";
  updateCheckbox = "n";
  limiteSms = 0;
  limiteMms = 0;
  idUser = "";
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem("isAdmin") != "true") {
      this.router.navigate(['/suivi']);
    }
    this.getPorts();
    this.users = [];
    this.getUsers();
    this.getSmpp();
    this.getInfoSfr();
  }
  update(): void {
    this.httpClient
      .post<any>(this.globalService.url + '/ports', {ports: this.ports},
        {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          alert('Modifié');
          this.getPorts();
        }
      }, () => {
      });
  }
  test(): void {
    this.status = "En cours";
    this.httpClient
      .get<any>(this.globalService.url + '/ports/test', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.status = "Terminé";
        }
      }, () => {
        this.status = "Terminé";
      });
  }
  getUsers(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/users', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          for (let p of response.personnels) {
            this.users.push(p);
          }
        }
      }, () => {
      });
  }
  getSmpp(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/smpp', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          for (let s of response.smpp) {
            this.users.push(s);
          }
        }
      }, () => {
      });
  }
  getPorts(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/ports', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          let ports: any = [];
          for (let p of response.ports) {
            p.updateCheckbox = 'n';
            ports.push(p);
          }
          this.ports = ports;
          console.log(this.ports);
        }
      }, () => {
      });
  }
  remiseAZero(number: number, type: string): void {
    this.httpClient
      .patch<any>(this.globalService.url + '/ports', {number: number, type: type}, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.getPorts();
        }
      }, () => {
      });
  }
  getInfoSfr(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/sfr', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.indicationSfrs = response.indicationSfrs;
        }
      }, () => {
      });
  }
  changeTestAutomatique(id: string): void {
    for (let p of this.ports) {
      if (p._id == id) {
        if (p.testAutomatique == 'y') {
          p.testAutomatique = 'n';
        } else {
          p.testAutomatique = 'y';
        }
      }
    }
  }
  changeUse(id: string): void {
    for (let p of this.ports) {
      if (p._id == id) {
        if (p.use == 'y') {
          p.use = 'n';
        } else {
          p.use = 'y';
        }
      }
    }
  }
  changeUpdate(id: string): void {
    for (let p of this.ports) {
      if (p._id == id) {
        if (p.updateCheckbox == 'y') {
          p.updateCheckbox = 'n';
        } else {
          p.updateCheckbox = 'y';
        }
      }
    }
  }
  changeLimiteSmsGeneral(): void {
    this.changeValue('limiteSms');
  }
  changeLimiteMmsGeneral(): void {
    this.changeValue('limiteMms');
  }
  changeTestAutomatiqueGeneral(): void {
    if (this.testAutomatique == 'y') {
      this.testAutomatique = 'n';
    } else {
      this.testAutomatique = 'y';
    }
    this.changeValue('testAutomatique');
  }
  changeUseGeneral(): void {
    if (this.use == 'y') {
      this.use = 'n';
    } else {
      this.use = 'y';
    }
    this.changeValue('general');
  }
  changeUpdateGeneral(): void {
    if (this.updateCheckbox == 'y') {
      this.updateCheckbox = 'n';
    } else {
      this.updateCheckbox = 'y';
    }
    this.changeValue('update');
  }
  changeValue(val: string): void {
    for (let p of this.ports) {
      if (val == 'etat') {
        if (this.general != '') {
          p.isOpen = this.general;
        }
      }
      if (val == 'type') {
        if (this.type != '') {
          p.type = this.type;
        }
      }
      if (val == 'testAutomatique') {
        if (this.testAutomatique != '') {
          p.testAutomatique = this.testAutomatique;
        }
      }
      if (val == 'general') {
        if (this.use != '') {
          p.use = this.use;
        }
      }
      if (val == 'update') {
        if (this.updateCheckbox != '') {
          p.updateCheckbox = this.updateCheckbox;
        }
      }
      if (val == 'limiteSms') {
        if (this.limiteSms != 0) {
          p.limiteSms = this.limiteSms;
        }
      }
      if (val == 'limiteMms') {
        if (this.limiteMms != 0) {
          p.limiteMms = this.limiteMms;
        }
      }
      if (val == 'user') {
        p.idUser = this.idUser;
      }
    }
  }
}
