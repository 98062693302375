<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3" style="padding-left: 0;">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title" *ngIf="!isAdmin">
      <app-credit-disponible></app-credit-disponible>
    </div>
    <div class="page-title">
      <h3 class="breadcrumb-header"></h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable" style="border: 3px solid black; background-color: #F7F9FA; border-radius: 20px; width: 600px; max-width: 99%;">
                  <tr>
                    <td style="text-align: center; font-size: 24px;">
                      <strong>Nouvelle Campagne</strong>
                    </td>
                  </tr>
                  <tr>
                    <td *ngIf="isAdmin" style="text-align: center;">
                      <div style="margin: 0 auto;width: 100%;">
                        <label>Sélectionner le client concerné</label>
                        <select class="form-control" [(ngModel)]="nouveauMessage.idClient">
                          <option [value]="" selected>Pour le client...</option>
                          <option *ngFor="let c of clients" [value]="c._id">{{c.identifiant}}</option>
                        </select>
                      </div>
                      <div style="color: red; font-size: 15px;" *ngIf="error.client">
                        Merci de choisir le client
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin: 0 auto;width: 100%; text-align: center;">
                        <label>Le message est-il le même pour tous les destinataires ?</label>
                        <select class="form-control" [(ngModel)]="nouveauMessage.messageUnique">
                          <option [value]="'1'">Le message à envoyer est le même pour tous les destinataires</option>
                          <option [value]="'0'">Le message à envoyer est différent par destinataire</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">
                      <label>Référence de la campagne</label>
                      <input class="form-control" [(ngModel)]="nouveauMessage.nomCampagne" placeholder="Référence de la campagne..." />
                      <div style="color: red; font-size: 15px;" *ngIf="error.reference">
                        Merci de saisir la référence de votre campagne
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin: 0 auto;width: 100%; text-align: center;">
                        <label>Le message doit-il partir dès maintenant ?</label>
                        <select class="form-control" [(ngModel)]="nouveauMessage.isScheduled">
                          <option [value]="'0'">Oui</option>
                          <option [value]="'1'">Non</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="nouveauMessage.isScheduled == '1'">
                    <td>
                      <div style="margin: 0 auto;width: 100%; text-align: center;">
                        <label>Choississez le début de diffsion</label>
                        <input type="datetime-local" class="form-control" [(ngModel)]="nouveauMessage.schedule" />
                        <div style="color: red; font-size: 15px;" *ngIf="error.schedule">
                          Merci de saisir une date de diffusion
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="nouveauMessage.messageUnique == '1'">
                    <td style="text-align: center;">
                      <label>Message à envoyer</label>
                      <textarea class="form-control" rows="8" (input)="control7Encoding()" [(ngModel)]="nouveauMessage.message" placeholder="Message..."></textarea>
                      <div [ngClass]="{'tooLong': compterTaille() > describedText[0]}" style="font-size: 16px;">
                        {{compterTaille()}} / {{describedText[0]}} characters - GSM {{describedText[1]}}-bit
                      </div>
                      <div style="color: red; font-size: 15px;" *ngIf="error.message">
                        Merci de saisir votre message
                      </div>
                      <div style="color: red; font-size: 15px;" *ngIf="error.tooLong">
                        Le message est trop long
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Télécharger votre fichier client (XLSX).<br />
                        <span *ngIf="nouveauMessage.messageUnique == '1'">Une seule colonne contenant les numéros de portable SANS titre.</span>
                        <span *ngIf="nouveauMessage.messageUnique == '0'">Deux colonnes SANS titre : La première contenant vos numéros, la deuxième contenant le messages correspondant.</span>
                      </label>
                      <table>
                        <tr>
                          <td style="width: 84%;">
                            <input type="file" (change)="fileChange($event)" id="fichier" />
                            <div style="border: 0; font-size: 14px; color: green;" *ngIf="!upload && nbImporter > 0">
                              {{nbImporter}} lignes détectées
                            </div>
                            <div style="border: 0; font-size: 14px;" *ngIf="upload">
                              <img src="assets/img/administration/downloading.gif" style="width: 60px;" />
                              Fichier en cours de téléchargement, merci de patienter
                            </div>
                            <div style="color: red; font-size: 15px;" *ngIf="error.destinataire">
                              Merci d'importer des destinataires
                            </div>
                          </td>
                          <td style="width: 16%;">
                            <button class="btn btn-success" style="width: 100%;height: 46px;" (click)="premiereCreation()" [disabled]="upload">Importer</button>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
