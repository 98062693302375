<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3" style="padding-left: 0;">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title" *ngIf="!isAdmin">
      <app-credit-disponible></app-credit-disponible>
    </div>
    <div class="page-title">
      <h3 class="breadcrumb-header">Suivi campagnes</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td *ngIf="isAdmin">
                      Clients
                    </td>
                    <td style="width: 150px;">
                      Ref. campagnes
                    </td>
                    <td style="width: 150px;">
                      TOTAL
                    </td>
                    <td style="width: 150px;">
                      DOUBLONS
                    </td>
                    <td style="width: 150px;">
                      Ancien blacklist
                    </td>
                    <td style="width: 150px;">
                      D&Eacute;LIVR&Eacute;S
                    </td>
                    <td style="width: 150px;">
                      PILE D'ENVOIES
                    </td>
                    <td style="width: 150px;">
                      FAILED
                    </td>
                    <td style="width: 150px;">
                      Nouveau Blacklist
                    </td>
                    <td style="width: 150px;">
                      REPONSES
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin">
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin">
                    </td>
                  </tr>
                  <tr *ngFor="let c of campagnes">
                    <td *ngIf="isAdmin">
                      <div *ngFor="let cc of clients">
                        <span *ngIf="cc._id == c.idClient">{{cc.identifiant}}</span>
                      </div>
                    </td>
                    <td style="width: 150px;">
                      <div class="reference">
                        <img src="assets/img/loupe.png" style="width: 25px;" /> <br />{{c.nomCampagne}} ({{ c.schedule * 1000 | date: 'dd/MM/yyyy HH:mm' }})
                        <div>
                          <textarea rows="13" disabled>{{c.message}}</textarea>
                        </div>
                      </div>
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterTotal('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.total}}
                    </td>
                    <td style="width: 150px;">
                      {{c.nbDoublons}}
                    </td>
                    <td style="width: 150px;">
                      {{c.nbAncienBlacklist}}
                    </td>
                    <td style="width: 150px;">
                      {{c.envoyer}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterPile('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.enAttente}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterFailed('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.failed}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterStop('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.nombreStop}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterReceptions('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.nombreReception}}
                    </td>
                    <td style="text-align: left;" *ngIf="isAdmin">
                      <div class="menu">
                        <button class="btn btn-success" (click)="relancerFailed(c._id)">Failed</button>
                      </div>
                    </td>
                    <td style="text-align: left;" *ngIf="isAdmin">
                      <div class="menu">
                        <button class="btn btn-danger" (click)="supprimer(c._id)">Supprimer</button>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
