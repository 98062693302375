import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.css']
})
export class StopsComponent implements OnInit {
  stops: any = [];
  sort = {
    limit: 50,
    offset: 0,
    total: 100
  }
  idClient = "";
  isAdmin = false;
  clients: Array<any> = [];
  campagnes: Array<any> = [];
  idCampagne = "";
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService) { }
  ngOnInit(): void {
    if (localStorage.getItem("isAdmin") == "true") {
      this.isAdmin = true;
    }
    this.getClients();
  }
  getClients(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/client', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.clients = response.personnel;
          for (let c of this.clients) {
            this.getStops(c._id);
          }
        }
      }, () => {
      });
  }
  exporter(idClient: number): void {
    this.httpClient
      .get<any>(this.globalService.url + '/stops/exportAll/' + idClient)
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/stops/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterFailed(idClient: number): void {
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportFailed/user/'+idClient+'/-1/-1', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/failed/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  getStops(idClient: string): void {
    this.httpClient
      .get<any>(this.globalService.url + '/stops/' + idClient + '/' + this.sort.limit + '/' + this.sort.offset, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          //this.stops = response.stops;
          for (let c of this.clients) {
            if (c._id == idClient) {
              c.totalStop = response.totalStop;
              c.totalFailed = response.totalFailed;
            }
          }
        }
      }, () => {
      });
  }
}
