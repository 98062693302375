import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-campagnes',
  templateUrl: './campagnes.component.html',
  styleUrls: ['./campagnes.component.css']
})
export class CampagnesComponent implements OnInit {
  nouveauMessage = {
    idClient: "",
    messageUnique: '1',
    nomCampagne: "",
    isScheduled: '0',
    schedule: '',
    message: "",
    tooLong: false
  }
  idCampagne = "";
  isAdmin = false;
  clients: Array<any> = [];
  files: Array<File> = [];
  upload = false;
  nbImporter = 0;
  error = {
    reference: false,
    message: false,
    destinataire: false,
    schedule: false,
    client: false,
    tooLong: false
  };
  list7Encoding = ['\n', ' ', '@', 'Δ', 'SP', '0', '¡', 'P', '¿', 'p', '£', '_', '!', '1', 'A', 'Q', 'a', 'q', '$', 'Φ', '"', '2', 'B', 'R', 'b', 'r', '¥', 'Γ', '#', '3', 'C', 'S', 'c', 's', 'è', 'Λ', '¤', '4', 'D', 'T', 'd', 't', 'é', 'Ω', '%', '5', 'E', 'U', 'e', 'u', 'ù', 'Π', '&', '6', 'F', 'V', 'f', 'v', 'ì', 'Ψ', '\'', '7', 'G', 'W', 'g', 'w', 'ò', 'Σ', '(', '8', 'H', 'X', 'h', 'x', 'Ç', 'Θ', ')', '9', 'I', 'Y', 'i', 'y', 'LF', 'Ξ', '*', ':', 'J', 'Z', 'j', 'z', 'Ø', '+', ';', 'K', 'Ä', 'k', 'ä', 'ø', 'Æ', ',', '<', 'L', 'Ö', 'l', 'ö', 'CR', 'æ', '-', '=', 'M', 'Ñ', 'm', 'ñ', 'Å', 'ß', '.', '>', 'N', 'Ü', 'n', 'ü', 'å', 'É', '/', '?', 'O', '§', 'o', 'à', '|', '^', '€', '{', '}', '[', ']', '~', '\\'];
  describedText: any = [160, 7];
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('isAdmin') == 'true') {
      this.isAdmin = true;
      this.getClients();
    } else {
      this.nouveauMessage.idClient = String(localStorage.getItem("id"));
    }
  }
  control7Encoding(): void {
    this.describedText = [];
    let type = 160;
    let sms = this.nouveauMessage.message;
    for (let j = 0; j < sms.length; j++) {
      let index = this.list7Encoding.indexOf(sms[j]);
      if (index == -1) {
        type = 70;
        break;
      }
    }
    if (type == 160) {
      this.describedText = [160, 7];
    } else {
      this.describedText = [70, 16];
    }
  }
  compterTaille(): number {
    this.nouveauMessage.tooLong = false;
    let txt = this.nouveauMessage.message;
    let taille = txt.length;

    if (taille > this.describedText[0]) {
      this.nouveauMessage.tooLong = true;
    }

    return taille;
  }
  getClients(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/users', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.clients = response.personnels;
        }
      }, () => {
      });
  }
  fileChange(element: any) {
    this.files = element.target.files;
  }
  premiereCreation(): void {
    this.error.client = false;
    this.error.reference = false;
    this.error.message = false;
    this.error.schedule = false;
    if (this.nouveauMessage.idClient != "" &&
      /*!this.nouveauMessage.tooLong &&*/
      this.nouveauMessage.nomCampagne != "" &&
      ((this.nouveauMessage.messageUnique == '0') || (this.nouveauMessage.messageUnique == '1' && this.nouveauMessage.message != "")) &&
      (this.nouveauMessage.isScheduled == '0' || (this.nouveauMessage.isScheduled == '1' && this.nouveauMessage.schedule != ''))) {
      this.add();
    } else {
      if (this.nouveauMessage.idClient == "") {
        this.error.client = true;
      }
      if (this.nouveauMessage.nomCampagne == "") {
        this.error.reference = true;
      }
      if (this.nouveauMessage.message == "") {
        this.error.message = true;
      }
      if (this.nouveauMessage.tooLong) {
        this.error.tooLong = true;
      }
      if (this.nouveauMessage.isScheduled == '1' && this.nouveauMessage.schedule == '') {
        this.error.schedule = true;
      }
    }
  }
  add(): void {
    this.httpClient
      .put<any>(this.globalService.url + '/campagnes', this.nouveauMessage, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.idCampagne = response.idCampagne;

          this.import();
        }
      }, () => {
      });
  }
  import(): void {
    this.upload = true;
    let formData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      formData.append("uploads[]", this.files[i], this.files[i].name);
    }
    formData.append("id", this.idCampagne);
    formData.append("message", this. nouveauMessage.message);
    formData.append("messageUnique", this. nouveauMessage.messageUnique);
    this.httpClient
      .post<any>(this.globalService.url + '/campagnes/import', formData)
      .subscribe((response) => {
        if (response.status) {
          this.nbImporter += response.total;
          this.upload = false;
          this.confirmationNouvelleCampagne();
        }
      }, () => {
        this.upload = false;
      });
  }
  confirmationNouvelleCampagne(): void {
    this.error.client = false;
    this.error.reference = false;
    this.error.message = false;
    this.error.destinataire = false;
    this.error.schedule = false;
    if (this.nouveauMessage.idClient != "" && this.nouveauMessage.nomCampagne != "") {
      this.httpClient
        .get<any>(this.globalService.url + '/campagnes/ajoutTerminer/' + this.idCampagne, {headers: this.globalService.headers()})
        .subscribe((response) => {
          if (response.status) {
            this.router.navigate(['/suivi']);
          }
        }, () => {

        });
    } else {
      if (this.nouveauMessage.idClient == "") {
        this.error.client = true;
      }
      if (this.nouveauMessage.nomCampagne == "") {
        this.error.reference = true;
      }
      if (this.nouveauMessage.message == "") {
        this.error.message = true;
      }
      if (this.nouveauMessage.isScheduled == '1' && this.nouveauMessage.schedule != '') {
        this.error.message = true;
      }
      if (this.nbImporter == 0) {
        this.error.destinataire = true;
      }
    }
  }
}
