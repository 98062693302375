import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-suivi',
  templateUrl: './suivi.component.html',
  styleUrls: ['./suivi.component.css']
})
export class SuiviComponent implements OnInit {
  clients: Array<any> = [];
  smpp: Array<any> = [];
  campagnes: Array<any> = [];
  idCampagne = "";
  isAdmin = false;
  userId: string | null = '';
  title = "";
  search = {
    debut: '',
    fin: ''
  }
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService) { }

  ngOnInit(): void {
    if (localStorage.getItem("isAdmin") == "true") {
      this.isAdmin = true;
    }
    this.userId = localStorage.getItem("id");
    this.getCampagnes();

    this.getClients();
    this.getSmpp();
    setInterval(() => {
      this.reactualiser();
    }, 20000);
  }
  reactualiser(): void {
    for (let c of this.campagnes) {
      this.getNombrePile('campagne', c._id);
      this.getNombreStop('campagne', c._id);
      this.getNombreReceptions('campagne', c._id);
    }
    for (let c of this.smpp) {
      this.getNombrePile('user', c._id);
      this.getNombreStop('user', c._id);
      this.getNombreReceptions('user', c._id);
    }
  }
  getClients(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/users', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.clients = response.personnels;
        }
      }, () => {
      });
  }
  getSmpp(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/smpp', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (this.isAdmin) {
            this.smpp = response.smpp;
          } else {
            for (let smpp of response.smpp) {
              if (smpp._id == this.userId) {
                this.smpp.push(smpp);
              }
            }
          }

          for (let c of this.smpp) {
            this.getNombrePile('user', c._id);
            this.getNombreStop('user', c._id);
            this.getNombreReceptions('user', c._id);
          }
        }
      }, () => {
      });
  }
  getCampagnes(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/campagnes',
        {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.campagnes = response.campagnes;
          for (let c of this.campagnes) {
            this.getNombrePile('campagne', c._id);
            this.getNombreStop('campagne', c._id);
            this.getNombreReceptions('campagne', c._id);
          }
        }
      }, () => {
      });
  }
  getNombrePile(type: string, id: string): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/nombre/' + type + '/' + id + '/' + debut + '/' + fin,
        {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (type == 'campagne') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.total = response.total;
                c.failed = response.failed;
                c.enAttente = response.enAttente;
                c.envoyer = response.envoyer;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.total = response.total;
                s.failed = response.failed;
                s.enAttente = response.enAttente;
                s.envoyer = response.envoyer;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  getNombreStop(type: string, id: string): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/stop/' + type + '/' + id + '/' + debut + '/' + fin,
        {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (type == 'campagne') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.nombreStop = response.total;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.nombreStop = response.total;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  getNombreReceptions(type: string, id: string): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/receptions/nombre/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (type == 'campagne') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.nombreReception = response.nombre;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.nombreReception = response.nombre;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  updateIsOn(url: string, id: string, isOn: string): void {
    this.httpClient
      .post<any>(this.globalService.url + '/'+url+'/isOn/', {id: id, isOn: isOn}, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (url == 'campagnes') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.isOn = isOn;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.isOn = isOn;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  exporterFailed(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportFailed/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/failed/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterStop(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportStop/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/stops/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterDelivered(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportDelivered/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/delivered/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterPile(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportPile/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/pile/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterTotal(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportTotal/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/total/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  supprimerSMPP(idUser: string, number: number) {
    this.httpClient
      .delete<any>(this.globalService.url + '/sms/' + idUser + '/' +number, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.reactualiser();
        }
      }, () => {
      });
  }
  exporterReceptions(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/receptions/export/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/reception/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  supprimer(id: string): void {
    if (confirm('Eves-vous sur de vouloir supprimer cet envoi ?')) {
      this.httpClient
        .delete<any>(this.globalService.url + '/campagnes/' + id, {headers: this.globalService.headers()})
        .subscribe((response) => {
          if (response.status) {
            this.getCampagnes();
          } else {
            alert('Erreur lors de la suppression');
          }
        }, () => {
        });
    }
  }
  relancerFailed(id: string): void {
    this.httpClient
      .get<any>(this.globalService.url + '/campagnes/relancer-failed/' + id, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.getCampagnes();
        } else {
          alert('Erreur lors de la suppression');
        }
      }, () => {
      });
  }
}
