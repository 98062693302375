import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login = {
    identifiant: '',
    password: ''
  }
  tentativeConnexion = true;
  error = '';
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    const id = localStorage.getItem('id') !== undefined && localStorage.getItem('id') !== null;
    const token = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null;
    if (id && token) {
      this.router.navigate(['/ports']);
    }
  }
  connexion() {
    this.httpClient
      .post<any>(this.globalService.url + '/users/login', this.login)
      .subscribe((response) => {
        if (response.status) {
          localStorage.setItem('id', response.id);
          localStorage.setItem('isAdmin', response.isAdmin);
          localStorage.setItem('token', response.token);
          this.router.navigate(['/ports']);
        } else {
          this.tentativeConnexion = false;
          this.error = 'Identifiant ou mot de passe incorrect';
        }
      }, () => {
        this.tentativeConnexion = false;
        this.error = 'Identifiant ou mot de passe incorrect';
      });
  }
}
