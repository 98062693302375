<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3" style="padding-left: 0;">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title" *ngIf="!isAdmin">
      <app-credit-disponible></app-credit-disponible>
    </div>
    <div class="page-title">
      <h3 class="breadcrumb-header">Gestion des stops</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td *ngIf="isAdmin">
                      Client
                    </td>
                    <td>
                      Réponses
                    </td>
                    <td>
                      FAILED
                    </td>
                    <td>
                      Exporter réponses
                    </td>
                    <td>
                      Exporter FAILED
                    </td>
                  </tr>
                  <tr *ngFor="let c of clients">
                    <td *ngIf="isAdmin">
                      <div>
                        {{c.identifiant}}
                      </div>
                    </td>
                    <td>
                      {{c.totalStop}}
                    </td>
                    <td>
                      {{c.totalFailed}}
                    </td>
                    <td>
                      <button class="btn btn-success" (click)="exporter(c._id)">Exporter réponses</button>
                    </td>
                    <td>
                      <button class="btn btn-success" (click)="exporterFailed(c._id)">Exporter FAILED</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
