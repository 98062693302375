<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title">
      <h3 class="breadcrumb-header">Gestion du paramétrage</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td colspan="3">
                      <button class="btn btn-success" (click)="debloquerVerrou()">Débloquer le verrou</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Numéro
                    </td>
                    <td>
                      Action
                    </td>
                    <td>
                      Valeur
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <button class="form-control btn-success" (click)="update()">Sauvegarder</button>
                    </td>
                  </tr>
                  <tr *ngFor="let p of parametrages">
                    <td>
                      {{p.number}}
                    </td>
                    <td>
                      {{p.name}}
                    </td>
                    <td>
                      <input type="time" class="form-control" [(ngModel)]="p.value" *ngIf="p.number == 4  || p.number == 5" />
                      <input type="text" class="form-control" [(ngModel)]="p.value" *ngIf="p.number != 29 && (p.number < 4 || p.number > 5)" />
                      <textarea class="form-control" rows="5" *ngIf="p.number == 100" [(ngModel)]="p.value"></textarea>
                      <select class="form-control" *ngIf="p.number == 29" [(ngModel)]="p.value">
                        <option value="y" [selected]="p.value == 'y'">Activé</option>
                        <option value="n" [selected]="p.value == 'n'">Désactivé</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <button class="form-control btn-success" (click)="update()">Sauvegarder</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
